var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "h-screen flex w-full bg-img" }, [
    _c(
      "div",
      {
        staticClass:
          "vx-col flex items-center justify-center flex-col sm:w-1/2 md:w-3/5 lg:w-3/4 xl:w-1/2 mx-auto text-center",
      },
      [
        _c("img", {
          staticClass: "mx-auto mb-4",
          attrs: {
            src: require("@/assets/images/pages/maintenance-2.png"),
            alt: "graphic-maintenance",
          },
        }),
        _c(
          "h1",
          { staticClass: "sm:mx-0 mx-4 mb-6 text-5xl d-theme-heading-color" },
          [_vm._v(_vm._s(_vm.$t("under-maintenance")))]
        ),
        _c("p", { staticClass: "sm:mx-0 mx-4 mb-6" }, [
          _vm._v(
            "Prezados usuários e clientes, estamos passando por uma manutenção preventiva e evolutiva. Em breve voltamos."
          ),
        ]),
        _vm._m(0),
        _c("vs-button", { attrs: { size: "large", to: "/" } }, [
          _vm._v(_vm._s(_vm.$t("back-to-home"))),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "sm:mx-0 mx-4 mb-6" }, [
      _vm._v("Dúvida ou ajuda, entrar em contato com o nosso suporte: "),
      _c("a", { attrs: { href: "mailto:ajuda@educatena.com.br" } }, [
        _vm._v("ajuda@educatena.com.br"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }